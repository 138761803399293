import React from "react";
import StorageService from "../utils/StorageService";
import consts from "../constants/consts";
import {sendEventToDataStream, setStreamData} from "../utils/commonUtil";

const defaultState = {
    user: null,
    openLogin: false,
    openAvatarDialog: false,
    openInterestsDialog: false,
    pageVisited: [],
    shouldProtectContent: false,
    loginSkipped: false,
    loginFrom: null,
    afterLoginNext: null,
    signUpEmail: null,
    updateState: (key, value) => {
    }
}

const UserContext = React.createContext(defaultState)

class UserProvider extends React.Component {
    state = {
        user: null,
        openLogin: false,
        openAvatarDialog: false,
        openInterestsDialog: false,
        shouldProtectContent: false,
        loginSkipped: false,
        loginFrom: null,
        afterLoginNext: null,
        signUpEmail: null,
        pageVisited: [],
    }

    updateState = (key, value) => {
        const stateObj = {};
        stateObj[key] = value;
        this.setState(stateObj);
        if (key === 'user') {
            StorageService.set(consts.USER_STORAGE_KEY, value);
            if (!value) {
                setStreamData({
                    country: undefined,
                    timezone: undefined,
                    city: undefined,
                    id: undefined
                });
                return;
            }
            setStreamData({
                country: value.country,
                timezone: value.timezone,
                city: value.city,
                id: value._id
            });
            sendEventToDataStream();
        }
    }

    componentDidMount() {
        const pageVisited = StorageService.getLocal(consts.PAGE_VISITED) || {};
        pageVisited[this.props.WEBSITE_ABBREVIATION] = pageVisited[this.props.WEBSITE_ABBREVIATION] || [];
        this.setState({
            user: StorageService.get(consts.USER_STORAGE_KEY),
            pageVisited: pageVisited[this.props.WEBSITE_ABBREVIATION]
        });
    }

    render() {
        const {children} = this.props
        const {
          user,
          openLogin,
          openAvatarDialog,
          openInterestsDialog,
          pageVisited,
          shouldProtectContent,
          loginSkipped,
          loginFrom,
          afterLoginNext,
          signUpEmail,
        } = this.state
        return (
            <UserContext.Provider
                value={{
                    user,
                    openLogin,
                    openAvatarDialog,
                    openInterestsDialog,
                    pageVisited,
                    shouldProtectContent,
                    loginSkipped,
                    loginFrom,
                    afterLoginNext,
                    signUpEmail,
                    updateState: this.updateState
                }}
            >
                {children}
            </UserContext.Provider>
        )
    }
}

export default UserContext

export {UserProvider}
