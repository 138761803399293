/*import axios from 'axios';
import AuthException from "../exceptions/unauthorized";
import { Auth0Client } from '@auth0/auth0-spa-js';
import {URL} from "../constants/urls";*/
import {Auth0Lock} from 'auth0-lock'

//let auth0

/*const getAuth0 = () => {
    if (!auth0) {
        auth0 = new Auth0Client({
            domain: 'age-group.eu.auth0.com',
            client_id: 'xzFs4ur9BU3zXH1eD8ZjldK4cqT4Siil',
            useRefreshTokens: true,
            audience: 'https://age-group-server',
            scope: 'openid profile',
            useCookiesForTransactions: true,
            cacheLocation: 'localstorage'
        });
    }
    return auth0;
}*/

export const initializeLock = (container, queryString, prefill) => {
  const redirectUrl = encodeURIComponent(`${window.location.pathname}${queryString}`)
  const options = {
    /*auth: {
        redirectUrl: `${window.location.origin}?r=${window.location.pathname}`
    },
    rememberLastLogin: !prompt,
    theme: {
        logo:            'https://www.datocms-assets.com/38567/1643352259-logo-ag-white.png',
        primaryColor:    '#2C86CC'
    },
    closable: false,*/

    auth: {
      redirectUrl: `${window.location.origin}/callback?r=${redirectUrl}`,
      responseType: 'token',
      audience: 'https://age-group-server'
    },
    /*//passwordlessMethod: "link",
    configurationBaseUrl: config.clientConfigurationBaseUrl,*/
    overrides: {
      __tenant: 'age-group',
      __token_issuer: 'https://age-group.eu.auth0.com/'
    },
    /*assetsUrl:  config.assetsUrl,
    allowedConnections: connection ? [connection] : null,*/
    //rememberLastLogin: !prompt,
    /*language: language,
    languageBaseUrl: config.languageBaseUrl,
    languageDictionary: languageDictionary,*/
    theme: {
      logo: 'https://www.datocms-assets.com/38567/1673987623-final-am-colored-2023.png',
      primaryColor: '#05ABD0'
    },
    closable: false,
    languageDictionary: {
      title: 'Welcome to AgeGroup'
    },
    cacheLocation: 'localstorage',
    initialScreen: 'signUp'
    /*additionalSignUpFields: [
      {
        name: 'dob',
        placeholder: 'Date of birth (dd/mm/yyyy)',
        validator: function (address) {
          function isValidDate (s) {
            // Assumes s is "dd/mm/yyyy"
            let valid = false
            if (/^\d\d\/\d\d\/\d\d\d\d$/.test(s)) {
              valid = true
            }
            if (!valid && /^\d\d\/\d\/\d\d\d\d$/.test(s)) {
              valid = true
            }
            if (!valid) {
              return false
            }
            const parts = s.split('/')
              .map((p) => parseInt(p, 10))
            parts[1] -= 1
            const d = new Date(parts[2], parts[1], parts[0])
            console.log(d)
            return d.getMonth() === parts[1] && d.getDate() === parts[0] && d.getFullYear() === parts[2]
          }

          return {
            valid: isValidDate(address),
            hint: 'Must be valid date' // optional
          }
        }
      },
      {
        type: 'select',
        name: 'country_code',
        placeholder: 'choose your location',
        options: [
          {value: '+44', label: '+44'},
          {value: '+1', label: '+1 USA'},
          {value: '+213', label: '+213 Algeria'},
          {value: '+376', label: '+376 Andorra'},
          {value: '+244', label: '+244 Angola'},
          {value: '+1264', label: '+1264 Anguilla'},
          {value: '+1268', label: '+1268 Antigua & Barbuda'},
          {value: '+54', label: '+54 Argentina'},
          {value: '+374', label: '+374 Armenia'},
          {value: '+297', label: '+297 Aruba'},
          {value: '+61', label: '+61 Australia'},
          {value: '+43', label: '+43 Austria'},
          {value: '+994', label: '+994 Azerbaijan'},
          {value: '+1242', label: '+1242 Bahamas'},
          {value: '+973', label: '+973 Bahrain'},
          {value: '+880', label: '+880 Bangladesh'},
          {value: '+1246', label: '+1246 Barbados'},
          {value: '+375', label: '+375 Belarus'},
          {value: '+32', label: '+32 Belgium'},
          {value: '+501', label: '+501 Belize'},
          {value: '+229', label: '+229 Benin'},
          {value: '+1441', label: '+1441 Bermuda'},
          {value: '+975', label: '+975 Bhutan'},
          {value: '+591', label: '+591 Bolivia'},
          {value: '+387', label: '+387 Bosnia Herzegovina'},
          {value: '+267', label: '+267 Botswana'},
          {value: '+55', label: '+55 Brazil'},
          {value: '+673', label: '+673 Brunei'},
          {value: '+359', label: '+359 Bulgaria'},
          {value: '+226', label: '+226 Burkina Faso'},
          {value: '+257', label: '+257 Burundi'},
          {value: '+855', label: '+855 Cambodia'},
          {value: '+237', label: '+237 Cameroon'},
          {value: '+1', label: '+1 Canada'},
          {value: '+238', label: '+238 Cape Verde Islands'},
          {value: '+1345', label: '+1345 Cayman Islands'},
          {value: '+236', label: '+236 Central African Republic'},
          {value: '+56', label: '+56 Chile'},
          {value: '+86', label: '+86 China'},
          {value: '+57', label: '+57 Colombia'},
          {value: '+269', label: '+269 Comoros'},
          {value: '+242', label: '+242 Congo'},
          {value: '+682', label: '+682 Cook Islands'},
          {value: '+506', label: '+506 Costa Rica'},
          {value: '+385', label: '+385 Croatia'},
          {value: '+53', label: '+53 Cuba'},
          {value: '+90392', label: '+90392 Cyprus North'},
          {value: '+357', label: '+357 Cyprus South'},
          {value: '+42', label: '+42 Czech Republic'},
          {value: '+45', label: '+45 Denmark'},
          {value: '+253', label: '+253 Djibouti'},
          {value: '+1809', label: '+1809 Dominica'},
          {value: '+1809', label: '+1809 Dominican Republic'},
          {value: '+593', label: '+593 Ecuador'},
          {value: '+20', label: '+20 Egypt'},
          {value: '+503', label: '+503 El Salvador'},
          {value: '+240', label: '+240 Equatorial Guinea'},
          {value: '+291', label: '+291 Eritrea'},
          {value: '+372', label: '+372 Estonia'},
          {value: '+251', label: '+251 Ethiopia'},
          {value: '+500', label: '+500 Falkland Islands'},
          {value: '+298', label: '+298 Faroe Islands'},
          {value: '+679', label: '+679 Fiji'},
          {value: '+358', label: '+358 Finland'},
          {value: '+33', label: '+33 France'},
          {value: '+594', label: '+594 French Guiana'},
          {value: '+689', label: '+689 French Polynesia'},
          {value: '+241', label: '+241 Gabon'},
          {value: '+220', label: '+220 Gambia'},
          {value: '+7880', label: '+7880 Georgia'},
          {value: '+49', label: '+49 Germany'},
          {value: '+233', label: '+233 Ghana'},
          {value: '+350', label: '+350 Gibraltar'},
          {value: '+30', label: '+30 Greece'},
          {value: '+299', label: '+299 Greenland'},
          {value: '+1473', label: '+1473 Grenada'},
          {value: '+590', label: '+590 Guadeloupe'},
          {value: '+671', label: '+671 Guam'},
          {value: '+502', label: '+502 Guatemala'},
          {value: '+224', label: '+224 Guinea'},
          {value: '+245', label: '+245 Guinea - Bissau'},
          {value: '+592', label: '+592 Guyana'},
          {value: '+509', label: '+509 Haiti'},
          {value: '+504', label: 'Honduras (+504)'},
          {value: '+852', label: 'Hong Kong (+852)'},
          {value: '+36', label: 'Hungary (+36)'},
          {value: '+354', label: 'Iceland (+354)'},
          {value: '+91', label: 'India (+91)'},
          {value: '+62', label: 'Indonesia (+62)'},
          {value: '+98', label: 'Iran (+98)'},
          {value: '+964', label: 'Iraq (+964)'},
          {value: '+353', label: 'Ireland (+353)'},
          {value: '+972', label: 'Israel (+972)'},
          {value: '+39', label: 'Italy (+39)'},
          {value: '+1876', label: 'Jamaica (+1876)'},
          {value: '+81', label: 'Japan (+81)'},
          {value: '+962', label: 'Jordan (+962)'},
          {value: '+7', label: 'Kazakhstan (+7)'},
          {value: '+254', label: 'Kenya (+254)'},
          {value: '+686', label: 'Kiribati (+686)'},
          {value: '+850', label: 'Korea North (+850)'},
          {value: '+82', label: 'Korea South (+82)'},
          {value: '+965', label: 'Kuwait (+965)'},
          {value: '+996', label: 'Kyrgyzstan (+996)'},
          {value: '+856', label: 'Laos (+856)'},
          {value: '+371', label: 'Latvia (+371)'},
          {value: '+961', label: 'Lebanon (+961)'},
          {value: '+266', label: 'Lesotho (+266)'},
          {value: '+231', label: 'Liberia (+231)'},
          {value: '+218', label: 'Libya (+218)'},
          {value: '+417', label: 'Liechtenstein (+417)'},
          {value: '+370', label: 'Lithuania (+370)'},
          {value: '+352', label: 'Luxembourg (+352)'},
          {value: '+853', label: 'Macao (+853)'},
          {value: '+389', label: 'Macedonia (+389)'},
          {value: '+261', label: 'Madagascar (+261)'},
          {value: '+265', label: 'Malawi (+265)'},
          {value: '+60', label: 'Malaysia (+60)'},
          {value: '+960', label: 'Maldives (+960)'},
          {value: '+223', label: 'Mali (+223)'},
          {value: '+356', label: 'Malta (+356)'},
          {value: '+692', label: 'Marshall Islands (+692)'},
          {value: '+596', label: 'Martinique (+596)'},
          {value: '+222', label: 'Mauritania (+222)'},
          {value: '+269', label: 'Mayotte (+269)'},
          {value: '+52', label: 'Mexico (+52)'},
          {value: '+691', label: 'Micronesia (+691)'},
          {value: '+373', label: 'Moldova (+373)'},
          {value: '+377', label: 'Monaco (+377)'},
          {value: '+976', label: 'Mongolia (+976)'},
          {value: '+1664', label: 'Montserrat (+1664)'},
          {value: '+212', label: 'Morocco (+212)'},
          {value: '+258', label: 'Mozambique (+258)'},
          {value: '+95', label: 'Myanmar (+95)'},
          {value: '+264', label: 'Namibia (+264)'},
          {value: '+674', label: 'Nauru (+674)'},
          {value: '+977', label: 'Nepal (+977)'},
          {value: '+31', label: 'Netherlands (+31)'},
          {value: '+687', label: 'New Caledonia (+687)'},
          {value: '+64', label: 'New Zealand (+64)'},
          {value: '+505', label: 'Nicaragua (+505)'},
          {value: '+227', label: 'Niger (+227)'},
          {value: '+234', label: 'Nigeria (+234)'},
          {value: '+683', label: 'Niue (+683)'},
          {value: '+672', label: 'Norfolk Islands (+672)'},
          {value: '+670', label: 'Northern Marianas (+670)'},
          {value: '+47', label: 'Norway (+47)'},
          {value: '+968', label: 'Oman (+968)'},
          {value: '+680', label: 'Palau (+680)'},
          {value: '+507', label: 'Panama (+507)'},
          {value: '+675', label: 'Papua New Guinea (+675)'},
          {value: '+595', label: 'Paraguay (+595)'},
          {value: '+51', label: 'Peru (+51)'},
          {value: '+63', label: 'Philippines (+63)'},
          {value: '+48', label: 'Poland (+48)'},
          {value: '+351', label: 'Portugal (+351)'},
          {value: '+1787', label: 'Puerto Rico (+1787)'},
          {value: '+974', label: 'Qatar (+974)'},
          {value: '+262', label: 'Reunion (+262)'},
          {value: '+40', label: 'Romania (+40)'},
          {value: '+7', label: 'Russia (+7)'},
          {value: '+250', label: 'Rwanda (+250)'},
          {value: '+378', label: 'San Marino (+378)'},
          {value: '+239', label: 'Sao Tome &amp; Principe (+239)'},
          {value: '+966', label: 'Saudi Arabia (+966)'},
          {value: '+221', label: 'Senegal (+221)'},
          {value: '+381', label: 'Serbia (+381)'},
          {value: '+248', label: 'Seychelles (+248)'},
          {value: '+232', label: 'Sierra Leone (+232)'},
          {value: '+65', label: 'Singapore (+65)'},
          {value: '+421', label: 'Slovak Republic (+421)'},
          {value: '+386', label: 'Slovenia (+386)'},
          {value: '+677', label: 'Solomon Islands (+677)'},
          {value: '+252', label: 'Somalia (+252)'},
          {value: '+27', label: 'South Africa (+27)'},
          {value: '+34', label: 'Spain (+34)'},
          {value: '+94', label: 'Sri Lanka (+94)'},
          {value: '+290', label: 'St. Helena (+290)'},
          {value: '+1869', label: 'St. Kitts (+1869)'},
          {value: '+1758', label: 'St. Lucia (+1758)'},
          {value: '+249', label: 'Sudan (+249)'},
          {value: '+597', label: 'Suriname (+597)'},
          {value: '+268', label: 'Swaziland (+268)'},
          {value: '+46', label: 'Sweden (+46)'},
          {value: '+41', label: 'Switzerland (+41)'},
          {value: '+963', label: 'Syria (+963)'},
          {value: '+886', label: 'Taiwan (+886)'},
          {value: '+7', label: 'Tajikstan (+7)'},
          {value: '+66', label: 'Thailand (+66)'},
          {value: '+228', label: 'Togo (+228)'},
          {value: '+676', label: 'Tonga (+676)'},
          {value: '+1868', label: 'Trinidad &amp; Tobago (+1868)'},
          {value: '+216', label: 'Tunisia (+216)'},
          {value: '+90', label: 'Turkey (+90)'},
          {value: '+7', label: 'Turkmenistan (+7)'},
          {value: '+993', label: 'Turkmenistan (+993)'},
          {value: '+1649', label: 'Turks &amp; Caicos Islands (+1649)'},
          {value: '+688', label: 'Tuvalu (+688)'},
          {value: '+256', label: 'Uganda (+256)'},
          {value: '+380', label: 'Ukraine (+380)'},
          {value: '+971', label: 'United Arab Emirates (+971)'},
          {value: '+598', label: 'Uruguay (+598)'},
          {value: '+7', label: 'Uzbekistan (+7)'},
          {value: '+678', label: 'Vanuatu (+678)'},
          {value: '+379', label: 'Vatican City (+379)'},
          {value: '+58', label: 'Venezuela (+58)'},
          {value: '+84', label: 'Vietnam (+84)'},
          {value: '+84', label: 'Virgin Islands - British (+1284)'},
          {value: '+84', label: 'Virgin Islands - US (+1340)'},
          {value: '+681', label: 'Wallis &amp; Futuna (+681)'},
          {value: '+969', label: 'Yemen (North)(+969)'},
          {value: '+967', label: 'Yemen (South)(+967)'},
          {value: '+260', label: 'Zambia (+260)'},
          {value: '+263', label: 'Zimbabwe (+263)'}
        ],
        prefill: '+44'
      },
      {
        name: 'phone',
        placeholder: 'Phone number',
        validator: function (phone) {
          return {
            valid: !isNaN(phone),
            hint: 'Must be valid number'
          }
        }
      }
    ]*/
  }
  if (container) {
    options.container = container
  }
  if (prefill && prefill.email) {
    options.prefill = {
      email: prefill.email
    }
  }
  return new Auth0Lock('xzFs4ur9BU3zXH1eD8ZjldK4cqT4Siil', 'age-group.eu.auth0.com', options)
}

export const getTokenSilently = async () => {
  return new Promise((resolve, reject) => {
    initializeLock()
      .checkSession({audience: 'https://age-group-server'}, (err, authResult) => {
        if (err) {
          reject(err)
          return
        }

        resolve(authResult.accessToken)
      })
  })
  //return getAuth0().getTokenSilently();
}
